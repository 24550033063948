<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col v-if="check_has_permission('exportDoctorManagement')" vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <vs-button
          align="left"
          @click="exportCsvHandler"
          icon-pack="feather"
          icon="icon-edit"
          v-if="!franchiseId"
          :disabled="users.length<=0"
        >Export as CSV</vs-button>
        <a id="downloadCustomerCsv" ref="downloadCustomerCSVRef" :href="customerCSVurl"></a>
      </vs-col>
      <vs-col style="text-align: right; width: 30%;" vs-justify="right" vs-align="right">
        <invite-user
          v-if="$store.state.AppActiveUser.userType === 'franchise' || $store.state.AppActiveUser.userType === 'subFranchise' || $store.state.AppActiveUser.userType === 'clinic' || $store.state.AppActiveUser.userType === 'subClinic'"
          class="m-3"
          invitationType="Doctor"
          initiator="management"
          :clinicCount="clinicCount"
          :invitedBy="$store.state.AppActiveUser.userType === 'subFranchise' ? 'franchise' : ($store.state.AppActiveUser.userType === 'subClinic') ? 'clinic': $store.state.AppActiveUser.userType"
        />
      </vs-col>
      <vs-col v-if="check_has_permission('createDoctorManagement')" style="text-align: right; width: 20%;" vs-justify="right" vs-align="right">
        <vs-button
          style="float:right;"
          v-if="canCreate"
          align="right"
          @click="createDoctorHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Create Doctor</vs-button>
      </vs-col>
    </vs-row>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item,index) in limitOptions"
            />
          </vs-select>
          <vs-select
            class="mr-6 selectExample"
            autocomplete
            placeholder="All Doctors"
            v-model="dataTableParams.filter"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in filterList"
            />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th>Photo</vs-th>
          <vs-th sort-key="availabitityStatus">Availability Status</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th>
          <!-- <vs-th sort-key="ahpraRegistrationNumber">AHPRA Number</vs-th> -->
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">{{ data[indextr].firstName | capitalize}}</vs-td>
            <vs-td :data="data[indextr].firstName">{{ data[indextr].lastName | capitalize}}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].profileImage">
              <div class="medium-img-wrapper" v-if="data[indextr].profileImage" @click="open_in_new_tab(data[indextr].profileImage)">
                <img :src="data[indextr].profileImage" class="img-fluid" alt />
              </div>
              <p v-else>N/A</p>
            </vs-td>

            <vs-td :data="data[indextr].availabitityStatus">{{ data[indextr].availabitityStatus }}</vs-td>

            <vs-td :data="data[indextr].status">
              <p v-if="data[indextr].status === 'Pending_Approval'" class="text-sm">Pending Approval</p>
              <p v-else class="text-sm">{{data[indextr].status}}</p>
            </vs-td>

            <vs-td :data="data[indextr].hasApprovedByAdmin">{{data[indextr].hasApprovedByAdmin}}</vs-td>
            <!-- <vs-td
              :data="data[indextr].ahpraRegistrationNumber"
            >{{ data[indextr].ahpraRegistrationNumber }}</vs-td>-->
            <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip v-if="check_has_permission('updateDoctorManagement')" text="Edit Doctor Details">
                <vs-button
                  style="float:left"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  color="success"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>

              <vx-tooltip v-if="check_has_permission('viewDoctorManagement')" text="View Doctor Details">
                <vs-button
                  style="float:left"
                  type="border"
                  size="small"
                  @click="viewDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-eye"
                  color="success"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <!--<request-doctor
              style="float:left"
              class="m-1"
              v-if="canRequestAssociation"
              :doctorId="data[indextr]._id" />-->
              <!--              <vs-button type="border" v-if="canDelete" size="small" @click="deleteDoctorHandler(data[indextr]._id)" icon-pack="feather" icon="icon-trash" color="danger" class="m-1"></vs-button>-->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import requestDoctorAssociation from "../../components/others/requestDoctorAssociation";
  import inviteUser from "../../components/others/inviteUser";

  export default {
    name: "franchiseAssociateDoctor",
    components: {
      "request-doctor": requestDoctorAssociation,
      inviteUser,
    },
    props: {
      canDelete: {
        type: Boolean,
        default: false,
      },
      franchiseId:{
        type:String,
        default:""
      },
      viewRoute: {
        type: String,
        default: "FranchiseDoctorEdit",
      },
      viewDetailRoute: {
        type: String,
        default: "SuperAdminDoctorDetail",
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
      createRoute: {
        type: String,
        default: "FranchiseDoctorCreate",
      },
      canRequestAssociation: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        noDataText: "Loading..",
        clinicCount: 0,
        customerCSVurl: false,
        isMounted: false,
        totalDocs: 0,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          page: 1,
          limit: 25,
          filter: "All",
          userType: this.$store.state.AppActiveUser.userType,
          franchiseId:this.franchiseId,
          activeClinicId: ""
        },
        serverResponded: false,
        users: [],
        limitOptions: [5, 10, 25, 50, 100],
        filterList: [
          { value: "All", text: "All Doctors" },
          { value: "Approved", text: "Approved" },
          { value: "Unapproved", text: "Unapproved" },
        ],
        awaitingSearch: null,
        activeClinicId: ''
      };
    },
    methods: {
      ...mapActions("doctor", ["fetchDoctors", "exportCsv"]),
      getDoctorsList() {
        let self = this;
        this.$vs.loading();
        this.fetchDoctors(self.dataTableParams).then((res) => {
          this.$vs.loading.close();
          self.users = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total;
          self.page = res.data.data.pagination.page;
          self.serverResponded = true;
          if (res.data.data && res.data.data.clinicCount) {
            if (
              this.$store.state.AppActiveUser.userType === "franchise" ||
              this.$store.state.AppActiveUser.userType === "subFranchise"
            )
              self.clinicCount = res.data.data.clinicCount;
            if (
              this.$store.state.AppActiveUser.userType === "clinic" ||
              this.$store.state.AppActiveUser.userType === "subClinic"
            )
              self.clinicCount = 1;
          }
          self.noDataText = "No Doctor Available";
        });
      },
      handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        this.awaitingSearch = setTimeout(() => {
          this.serverResponded = false;
          this.dataTableParams.search = searching;
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.getDoctorsList();
        }, 500);
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getDoctorsList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getDoctorsList();
      },
      editDetailHandler(id) {
        this.$router.push({ name: this.viewRoute, params: { doctorId: id } });
      },
      viewDetailHandler(id) {
        this.$router.push({
          name: this.viewDetailRoute,
          params: { doctorId: id },
        });
      },
      createDoctorHandler() {
        this.$router.push({ name: this.createRoute });
      },
      exportCsvHandler() {
        let _instance = this;
        let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

        //csvLinkInstance.click();
        this.exportCsv()
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "freshclinic-doctors.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    computed: {
      totalPage: function () {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    watch: {
      "dataTableParams.page": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getDoctorsList();
        }
      },
      "dataTableParams.limit": function (newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getDoctorsList();
        }
      },
      "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
        if (newfilterVal !== oldFilterVal) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.filter = newfilterVal;
          this.getDoctorsList();
        }
      },
      "$store.state.AppActiveClinicId": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.activeClinicId = newVal;
          this.getDoctorsList();
        }
      }
    },
    async created() {
      this.dataTableParams.activeClinicId = await this.$store.state.AppActiveClinicId;
      this.getDoctorsList();
    },
  };
</script>
